import axios from 'axios';
import { supabase } from './supabase';

const BASE_URL = process.env.REACT_APP_PROXY;

export const credentialsRepo = {
    searchDomain: async (domain: string) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/capi/search/domain/${domain}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                params: {
                    systems_per_page: 10,
                    creds_per_page: 10
                }
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    searchEmailDomain: async (emailDomain: string) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/capi/search/email_domain/${emailDomain}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                params: {
                    systems_per_page: 10,
                    creds_per_page: 10
                }
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    }
}