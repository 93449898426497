import { Accordion, Button, Collapse, Flex, Heading, useToast, Text } from "@chakra-ui/react";
import CustomLineGraph from "../components/CustomLineGraph";
import CustomPieChart from "../components/CustomPieChart";
import CustomAccordionItem from "../components/CustomAccordionItem";
import CustomTable from "../components/CustomTable";
import CVEBg from "../assets/cve-bg.png";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { searchTagRepository } from "../api/searchRepository";
import { addDays, format } from "date-fns";
import groupAttacksByDay from "../utils/groupSeenOn";
import { AxiosResponse } from "axios";
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";
import { ChevronDownIcon } from "@chakra-ui/icons";
import "../assets/styles/searchCommon.css"
import { ITagHistory, ITagRelatedIps, ITagRelatedCves, ITagIpsData, ITagIpLogs, ITagDetails } from "../types/searchTag";
import DateDropDown from "../components/DateDropDown";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { dateRangeConfig, dateRangePropsConfig } from "../constants/date";

export default function SearchTag() {
    const navParams = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [tagHistory, setTagHistory] = useState<ITagHistory | undefined>(undefined);
    const [tagHistoryLoading, setTagHistoryLoading] = useState<boolean>(true);
    const [tagRelatedIps, setTagRelatedIps] = useState<ITagRelatedIps | undefined>(undefined);
    const [tagRelatedCves, setTagRelatedCves] = useState<ITagRelatedCves | undefined>(undefined);
    const [tagIpsData, setTagIpsData] = useState<ITagIpsData | undefined>(undefined);
    const [tagIpLogs, setTagIpLogs] = useState<{ [key: string]: ITagIpLogs }>({});
    const [tagDetails, setTagDetails] = useState<ITagDetails | undefined>(undefined)
    const toast = useToast();
    const [ipsDataPage, setIpsDataPage] = useState<number>(1);
    const [ipsLogsPage, setIpsLogsPage] = useState<number>(1);
    const [accordionOpenedIp, setAccordionOpenedIp] = useState<string>("");
    const [tagCollapse, setTagCollapse] = useState(false);

    const filterDateOptions = {
        all: { index: -1, string: "All" },
        day: { index: 1, string: "Today" },
        week: { index: 7, string: "This Week" },
        month: { index: 30, string: "This Month" },
    }
    const [filterDate, setFilterDate] = useState(filterDateOptions.month)
    const [selectedDates, setSelectedDates] = useState<Date[]>([addDays(new Date(), (-1 * filterDate.index)), new Date()]);
    let startDate = Math.ceil((+selectedDates[0]) / 1000);
    let endDate = Math.ceil((86400000 + +selectedDates[1]) / 1000);

    function getTagIpsData(page: number) {
        searchTagRepository.getTagIpsData(navParams.searchString!!, startDate, endDate).then((response: AxiosResponse<ITagIpsData>) => {
            setTagIpsData(response.data);
        })
    }

    async function getIpLogs(ip: string, page: number = 1) {
        const pathResponse = (await searchTagRepository.getTagIpLogs(navParams.searchString!!, ip, startDate, endDate, page)).data

        setTagIpLogs(prevState => ({
            ...prevState,
            [ip]: pathResponse
        }));
    }

    useEffect(() => {
        if (navParams.searchString && selectedDates[0] && selectedDates[1]) {
            searchTagRepository.getTagHistory(navParams.searchString, startDate, endDate).then((response: AxiosResponse<ITagHistory>) => {
                if (response.data.seenOn.length !== 0) {
                    (response.data.seenOn as any) = groupAttacksByDay(response.data.seenOn);
                    setTagHistory(response.data)
                    setTagHistoryLoading(false);

                    searchTagRepository.getTagRelatedCves(navParams.searchString!!, startDate, endDate).then((response: AxiosResponse<ITagRelatedCves>) => {
                        setTagRelatedCves(response.data);
                    })

                    searchTagRepository.getTagRelatedIps(navParams.searchString!!, startDate, endDate).then((response: AxiosResponse<ITagRelatedIps>) => {
                        setTagRelatedIps(response.data);
                    })

                    searchTagRepository.getTagDetails(navParams.searchString!!).then((response: AxiosResponse<ITagDetails>) => {
                        setTagDetails(response.data)
                    })

                    getTagIpsData(ipsDataPage);
                } else if (filterDate.index !== -1) {
                    toast({
                        title: "No results found within " + filterDate.index + " days",
                        status: "warning",
                        duration: 3000
                    })
                    setFilterDate(filterDateOptions.all)
                    setSelectedDates([new Date(0), new Date()]);
                } else {
                    toast({
                        title: "No results found for " + navParams.searchString,
                        status: "warning",
                        duration: 3000
                    })
                }
            })
        }
    }, [navParams.searchString, selectedDates]);

    useEffect(() => {
        tagIpsData && tagIpsData.data.forEach(async (ipObj) => {
            (await getIpLogs(ipObj.ip, 1));
            setIpsLogsPage(1)
        })
    }, [tagIpsData])

    useEffect(() => {
        setIsLoading(true);
        searchTagRepository.getTagIpsData(navParams.searchString!!, startDate, endDate, ipsDataPage).then((res: AxiosResponse<ITagIpsData>) => {
            setTagIpsData(res.data)
            setIsLoading(false)
        })
    }, [ipsDataPage])

    useEffect(() => {
        setIsLoading(true);
        accordionOpenedIp && getIpLogs(accordionOpenedIp, ipsLogsPage).then(() => setIsLoading(false))
    }, [ipsLogsPage])

    return (
        <>
            <Loading show={isLoading} />
            <Flex direction="column" className="cveMainDetails" backgroundImage={CVEBg}>
                <Heading as='h1' size='3xl' className={"searchQueryTitle"} textAlign={"center"}>{navParams.searchString}</Heading>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Flex style={{ margin: "6px 0px 9px auto" }} columnGap={"9px"}>
                        <RangeDatepicker
                            selectedDates={+selectedDates[0] == +new Date(0) ? [] : selectedDates}
                            onDateChange={setSelectedDates}
                            propsConfigs={dateRangePropsConfig}
                            configs={dateRangeConfig}
                        />
                        <DateDropDown callback={(value) => {
                            setFilterDate(value);
                            value.index != -1 ? setSelectedDates([addDays(new Date(), (-1 * value.index)), new Date()]) : setSelectedDates([new Date(0), new Date()])
                        }} filterDate={filterDate} />
                    </Flex>
                    <CustomLineGraph isLoading={tagHistoryLoading} groupedLogs={tagHistory?.seenOn} aspect={3} />
                </div>
                <div className="cvePage-pies-wrapper" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <CustomPieChart data={tagRelatedIps} heading={"IPs Related to this Tag"} />
                    </div>
                    <div>
                        <CustomPieChart data={tagRelatedCves} heading={"CVEs Related to this Tag"} />
                    </div>
                </div>
                {/* START INFO COMPONENT TYPE2 */}
                <div className="outerBgBox" style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "space-around" }}>
                    <div className="innerBgBox" style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-around", height: "100%", padding: "33px" }}>
                        <Collapse startingHeight={63} style={{ width: "54%" }} in={tagCollapse}>
                            <Flex flexDirection="column">
                                <Text fontSize="xl" color={"#E41E20"}>Other Related Tags</Text>
                                <Text fontSize="xl" color={""}>{tagDetails !== undefined && tagDetails.tags.length > 0 && tagDetails.tags.join(", ")}</Text>
                            </Flex>
                        </Collapse>
                        <Flex width="42%" alignItems={"flex-start"} flexDir={'column'}>
                            <Text fontSize="xl" color={"#E41E20"} margin={"0 auto"}>All Time Total Attempts</Text>
                            <div className="outerBgBox" style={{ padding: "6px 15px", margin: "0 auto", width: "fit-content" }}>
                                <Text fontSize="xl" fontWeight={"600"} letterSpacing={"9px"} color={"#E41E20"}>{tagDetails?.totalAttempts}</Text>
                            </div>
                        </Flex>
                        <Flex marginTop="33px" width="100%" justifyContent={"center"}><Button onClick={() => setTagCollapse(!tagCollapse)}><ChevronDownIcon color="#E41E20" style={{ transition: ".3s", transform: 'rotate(' + (tagCollapse ? "180deg" : "0deg") + ')' }} /></Button></Flex>
                    </div>
                </div>
                {/* END INFO COMPONENT TYPE2 */}
                <Accordion allowToggle width={"100%"}>
                    {tagIpsData && tagIpsData.data.map((ipObj, i: number) =>
                        <CustomAccordionItem key={i}
                            accordionTitle={ipObj.ip === "" ? "Other Attacks" : ipObj.ip}
                            accordionText={`${ipObj.attacks} Attacks - ${ipObj.country} - ${ipObj.asn} - Latest attack: ${format(new Date(ipObj.latestAttack * 1000), "yyyy-MM-dd HH:mm:ss")}`}
                            onClick={() => { setAccordionOpenedIp(ipObj.ip) }}>
                            {tagIpsData && Object.hasOwn(tagIpLogs, ipObj.ip) && tagIpsData.data.length > 0 &&
                                // <CustomTable
                                //     headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                //     rowsData={[["a1", "a2", "a3"], ["a1", "a2", "a3"], ["a1", "a2", "a3"]]} />
                                <>
                                    <CustomTable
                                        headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                        rowsData={
                                            tagIpLogs[ipObj.ip].logs.map(log => [format(new Date(log.time * 1000), "yyyy-MM-dd HH:mm:ss"), log.path, log.userAgent, log.queryString])
                                        } />
                                    <Flex className="data-logs-pagination-wrapper" justifyContent={"space-between"}>
                                        <Pagination
                                            currentPage={ipsLogsPage}
                                            setPage={setIpsLogsPage}
                                            maxPage={tagIpLogs[ipObj.ip].pagination.maxPage}
                                        />
                                    </Flex>
                                </>
                            }
                        </CustomAccordionItem>
                    )}
                </Accordion>
                {tagIpsData &&
                    <Flex className="pagination-wrapper" w={"100%"} justifyContent={"space-between"}>
                        <Pagination currentPage={ipsDataPage} setPage={setIpsDataPage} maxPage={tagIpsData.pagination.maxPage} />
                    </Flex>
                }
            </Flex>
        </>
    )
}