// api/ipHistoryRepository.js
import axios from 'axios';
import { supabase } from './supabase';

const BASE_URL = process.env.REACT_APP_PROXY;

export const searchIpRepository = {
    getIpDetails: async (ip: String) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/ip-details`, {
                params: {
                    ip: ip
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getIpHistory: async (ip: String, fromTime?: number, toTime?: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/ip-history`, {
                params: {
                    ip: ip,
                    from_time: fromTime,
                    to_time: toTime
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getIpRelatedCves: async (ip: String, fromTime?: number, toTime?: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/ip-cves-related`, {
                params: {
                    ip: ip,
                    from_time: fromTime,
                    to_time: toTime
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getIpRelatedTags: async (ip: String, fromTime?: number, toTime?: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/ip-tags-related`, {
                params: {
                    ip: ip,
                    from_time: fromTime,
                    to_time: toTime
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getIpCvesData: async (ip: String, fromTime?: number, toTime?: number, page: number = 1) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/ip-general-data`, {
                params: {
                    ip: ip,
                    from_time: fromTime,
                    to_time: toTime,
                    page: page
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getIpGeneralLogs: async (tag_id: number, ip: String, fromTime?: number, toTime?: number, page: number = 1) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/ip-general-logs`, {
                params: {
                    tag_id: tag_id,
                    ip: ip,
                    from_time: fromTime,
                    to_time: toTime,
                    page: page
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    }
};

export const searchCveRepository = {
    getCveDetails: async (cve: String) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/cve-details`, {
            // return await axios.get(`https://oc.klajdi.net/cve-details`, {
                params: {
                    cve: cve,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getCveNIST: async (cve: String) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/cve-nist`, {
            // return await axios.get(`https://oc.klajdi.net/cve-details`, {
                params: {
                    cve: cve,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getCveHistory: async (cve: String, fromTime?: number, toTime?: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/cve-history`, {
            // return await axios.get(`https://oc.klajdi.net/cve-history`, {
                params: {
                    cve: cve,
                    from_time: fromTime,
                    to_time: toTime
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getCveRelatedIps: async (cve: String, fromTime?: number, toTime?: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/cve-ips-related`, {
            // return await axios.get(`https://oc.klajdi.net/cve-ips-related`, {
                params: {
                    cve: cve,
                    from_time: fromTime,
                    to_time: toTime
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getCveIpsData: async (cve: String, fromTime?: number, toTime?: number, page: number = 1) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/cve-ips-data`, {
            // return await axios.get(`https://oc.klajdi.net/cve-ips-data`, {
                params: {
                    cve: cve,
                    from_time: fromTime,
                    to_time: toTime,
                    page: page
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getCveIpLogs: async (cve: string, ip: String, fromTime?: number, toTime?: number, page: number = 1) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/cve-ip-logs`, {
            // return await axios.get(`https://oc.klajdi.net/cve-ip-logs`, {
                params: {
                    ip: ip,
                    cve: cve,
                    from_time: fromTime,
                    to_time: toTime,
                    page: page
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    }
};

export const searchPathRepository = {
    getPathHistory: async (path: String, fromTime?: number, toTime?: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/path-history`, {
            // return await axios.get(`https://oc.klajdi.net/path-history`, {
                params: {
                    path: path,
                    from_time: fromTime,
                    to_time: toTime
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getPathRelatedIps: async (path: String, fromTime?: number, toTime?: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/path-ips-related`, {
            // return await axios.get(`https://oc.klajdi.net/path-ips-related`, {
                params: {
                    path: path,
                    from_time: fromTime,
                    to_time: toTime
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getPathDetails: async (path: String) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/path-details`, {
            // return await axios.get(`https://oc.klajdi.net/path-cves-related`, {
                params: {
                    path: path,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getPathIpsData: async (path: String, fromTime?: number, toTime?: number, page: number = 1) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/path-ips-data`, {
            // return await axios.get(`https://oc.klajdi.net/path-ips-data`, {
                params: {
                    path: path,
                    from_time: fromTime,
                    to_time: toTime,
                    page: page
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getPathIpLogs: async (path: string, ip: String, fromTime?: number, toTime?: number, page: number = 1) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/path-ip-logs`, {
            // return await axios.get(`https://oc.klajdi.net/path-ip-logs`, {
                params: {
                    path: path,
                    ip: ip,
                    from_time: fromTime,
                    to_time: toTime,
                    page: page
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    }
};

export const searchTagRepository = {
    getTagDetails: async (tag: String) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/tag-details`, {
            // return await axios.get(`https://oc.klajdi.net/vendor-details`, {
                params: {
                    tag: tag,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getTagHistory: async (tag: String, fromTime?: number, toTime?: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/tag-history`, {
            // return await axios.get(`https://oc.klajdi.net/vendor-history`, {
                params: {
                    tag: tag,
                    from_time: fromTime,
                    to_time: toTime
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getTagRelatedIps: async (tag: String, fromTime?: number, toTime?: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/tag-ips-related`, {
            // return await axios.get(`https://oc.klajdi.net/vendor-ips-related`, {
                params: {
                    tag: tag,
                    from_time: fromTime,
                    to_time: toTime
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getTagRelatedCves: async (tag: String, fromTime?: number, toTime?: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/tag-cves-related`, {
            // return await axios.get(`https://oc.klajdi.net/vendor-cves-related`, {
                params: {
                    tag: tag,
                    from_time: fromTime,
                    to_time: toTime
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getTagIpsData: async (tag: String, fromTime?: number, toTime?: number, page: number = 1) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/tag-ips-data`, {
            // return await axios.get(`https://oc.klajdi.net/vendor-ips-data`, {
                params: {
                    tag: tag,
                    from_time: fromTime,
                    to_time: toTime,
                    page: page
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getTagIpLogs: async (tag: string, ip: String, fromTime?: number, toTime?: number, page: number = 1) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/tag-ip-logs`, {
            // return await axios.get(`https://oc.klajdi.net/tag-ip-logs`, {
                params: {
                    tag: tag,
                    ip: ip,
                    from_time: fromTime,
                    to_time: toTime,
                    page: page
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    }
};