import { Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react"
import { IoFilterSharp } from "react-icons/io5"
import { filterDateOptions } from "../constants/date";
import { IFilterDate } from "../types/common";

interface IDateDropDownProps {
    callback: (value: IFilterDate) => void;
    filterDate: IFilterDate;
}

const DateDropDown = ({callback, filterDate}: IDateDropDownProps) => {
    return (
        <Menu>
            <MenuButton as={Button} className="dateFilterChart" sx={{
                padding: "12px 30px",
                alignSelf: "end",
                background: "rgba(34, 34, 34, 1)",
                zIndex: 1,
                borderRadius: "9px"
            }} leftIcon={<div style={{ paddingRight: "15px" }}><IoFilterSharp /></div>}>
                {filterDate.string}
            </MenuButton>
            <MenuList>
                <MenuItem onClick={() => callback(filterDateOptions.day)}>Today</MenuItem>
                <MenuItem onClick={() => callback(filterDateOptions.week)}>Week</MenuItem>
                <MenuItem onClick={() => callback(filterDateOptions.month)}>Month</MenuItem>
                <MenuItem onClick={() => callback(filterDateOptions.all)}>All</MenuItem>
            </MenuList>
        </Menu>
    )
}

export default DateDropDown