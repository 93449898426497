import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { IBlogPost } from "../../types/blog";
import { useNavigate } from "react-router-dom";

export default function BlogCard({ ...post }: IBlogPost) {
    const navigate = useNavigate();
    const postPreviewContent = [post.content.blocks.map(block => block.text)].join(" ").substring(0, 600);
    return (
        <Flex className="blog-post">
            <div className="post-image-wrapper">
                <Image src={process.env.REACT_APP_SUPABASE + "/storage/v1/object/public/blog/" + post.thumbnail} />
            </div>
            <div className="post-content">
                <Text className="post-title">{post.title}</Text>
                <Text className="post-content-preview">{postPreviewContent}</Text>
                <Button mt={"auto"} borderRadius={"9px"} onClick={()=> navigate("/reports/"+post.id)}>Read More</Button>
            </div>
        </Flex>
    )
}