export default function groupAttacksByDay(seenOn: number[]) {
  const startTime = +new Date()
  // Create a Map to store the counts for each day
  const countsByDay = new Map();

  // Iterate through the timestamps
  seenOn.forEach(timestamp => {
    // Create a Date object from the timestamp
    const date = new Date(timestamp * 1000);

    // Format the date as "DD Month YYYY"
    const formattedDate = date.toLocaleString('en-US', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });

    // Increment the count for this day
    countsByDay.set(formattedDate, (countsByDay.get(formattedDate) || 0) + 1);
  });

  // Convert the Map to an array of objects
  let result = Array.from(countsByDay, ([day, count]) => ({ day, count }));

  // If there's only one group, group by hour instead
  if (result.length === 1) {
    const countsByHour = new Map();

    seenOn.forEach(timestamp => {
      const date = new Date(timestamp * 1000);
      const formattedHour = date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        hour12: true
      });

      countsByHour.set(formattedHour, (countsByHour.get(formattedHour) || 0) + 1);
    });

    result = Array.from(countsByHour, ([hour, count]) => ({ day: hour, count }));
  }
  return result;
}

export function groupAttacks(seenOn: number[]) {
  if (seenOn.length === 0) return [];

  // If there's only one value, return it formatted
  if (seenOn.length === 1) {
    const date = new Date(seenOn[0] * 1000);
    return [{
      day: date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }),
      count: 1
    }];
  }

  // Sort timestamps
  const sortedTimestamps = [...seenOn].sort((a, b) => a - b);

  // Calculate time range
  const startTime = sortedTimestamps[0];
  const endTime = sortedTimestamps[sortedTimestamps.length - 1];
  const timeRange = endTime - startTime;

  // Time constants
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_DAY = 86400;
  const SECONDS_IN_MONTH = 2592000; // Approximate
  const SECONDS_IN_YEAR = 31536000; // Approximate

  let groups = new Map<string, number>();

  if (timeRange >= SECONDS_IN_YEAR) {
    // Group by month with zero counts
    const startDate = new Date(startTime * 1000);
    const endDate = new Date(endTime * 1000);

    // Set to first day of start month
    startDate.setDate(1);
    startDate.setHours(0, 0, 0, 0);

    // Set to first day of next month for end date
    endDate.setDate(1);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setHours(0, 0, 0, 0);

    // Generate all months
    const currentDate = new Date(startDate);
    while (currentDate < endDate) {
      const formattedDate = currentDate.toLocaleString('en-US', {
        month: 'long',
        year: 'numeric'
      });
      groups.set(formattedDate, 0);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    // Fill in actual counts
    sortedTimestamps.forEach(timestamp => {
      const date = new Date(timestamp * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        month: 'long',
        year: 'numeric'
      });
      groups.set(formattedDate, (groups.get(formattedDate) || 0) + 1);
    });

  } else if (timeRange >= SECONDS_IN_MONTH) {
    // Group by day with zero counts
    const startDate = new Date(startTime * 1000);
    const endDate = new Date(endTime * 1000);

    // Set to start of day
    startDate.setHours(0, 0, 0, 0);

    // Set to start of next day for end date
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(0, 0, 0, 0);

    // Generate all days
    const currentDate = new Date(startDate);
    while (currentDate < endDate) {
      const formattedDate = currentDate.toLocaleString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      });
      groups.set(formattedDate, 0);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Fill in actual counts
    sortedTimestamps.forEach(timestamp => {
      const date = new Date(timestamp * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      });
      groups.set(formattedDate, (groups.get(formattedDate) || 0) + 1);
    });

  } else if (timeRange >= SECONDS_IN_DAY) {
    // Group by hour with zero counts
    const hourStart = Math.floor(startTime / SECONDS_IN_HOUR) * SECONDS_IN_HOUR;
    const hourEnd = Math.ceil(endTime / SECONDS_IN_HOUR) * SECONDS_IN_HOUR;

    // Generate all hours
    for (let timestamp = hourStart; timestamp <= hourEnd; timestamp += SECONDS_IN_HOUR) {
      const date = new Date(timestamp * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        hour12: true
      });
      groups.set(formattedDate, 0);
    }

    // Fill in actual counts
    sortedTimestamps.forEach(timestamp => {
      const hourTimestamp = Math.floor(timestamp / SECONDS_IN_HOUR) * SECONDS_IN_HOUR;
      const date = new Date(hourTimestamp * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        hour12: true
      });
      groups.set(formattedDate, (groups.get(formattedDate) || 0) + 1);
    });

  } else {
    // For hours or less, use equal intervals
    const NUMBER_OF_GROUPS = 120;
    const timePerGroup = timeRange / NUMBER_OF_GROUPS;

    // Generate all intervals
    for (let i = 0; i < NUMBER_OF_GROUPS; i++) {
      const groupTimestamp = startTime + (i * timePerGroup);
      const date = new Date(groupTimestamp * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
      groups.set(formattedDate, 0);
    }

    // Fill in actual counts
    sortedTimestamps.forEach(timestamp => {
      const groupIndex = Math.floor((timestamp - startTime) / timePerGroup);
      const groupTimestamp = startTime + (groupIndex * timePerGroup);
      const date = new Date(groupTimestamp * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
      groups.set(formattedDate, (groups.get(formattedDate) || 0) + 1);
    });
  }

  // Convert to array and sort
  const result = Array.from(groups, ([day, count]) => ({ day, count }))
    .sort((a, b) => {
      const dateA = new Date(a.day).getTime();
      const dateB = new Date(b.day).getTime();
      return dateA - dateB;
    });

  return result;
}