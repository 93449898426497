import { Box, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast, VStack, Image, Flex, IconButton } from "@chakra-ui/react";
import { supabase } from "../api/supabase"
import { useBlogposts } from "../hooks/useBlogPosts";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
// import { HeadingNode, QuoteNode } from "@lexical/rich-text";
// import { CodeNode, CodeHighlightNode } from "@lexical/code";
// import { AutoLinkNode, LinkNode } from "@lexical/link";
// import { ListNode, ListItemNode } from "@lexical/list";
// import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
// import lexicalTheme from "../theme/lexical";
// import LexicalEditor from "./LexicalEditor/LexicalEditor";
// import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, RawDraftContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { IoBowlingBallSharp } from "react-icons/io5";
import BlogCard from "../components/Blog/BlogCard";
import { NewMessage } from "@styled-icons/entypo/NewMessage"
import "../assets/styles/blog.css";


// function Placeholder() {
//     return (
//         <div className="editor-placeholder">
//             Play around with the Markdown plugin...
//         </div>
//     );
// }

export default function Blog() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [title, setTitle] = useState('');
    const toast = useToast();
    const { posts, error } = useBlogposts();
    const [image, setImage] = useState<any>(null);
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [contentState, setContentState] = useState<RawDraftContentState>();

    const onDrop = useCallback((acceptedFiles: any) => {
        setImage(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'image/*': [] } });

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            let imagePath = null;
            if (image) {
                const fileExt = image.name.split('.').pop();
                const fileName = `${Math.random()}.${fileExt}`;
                const filePath = `posters/${fileName}`;

                let { error: uploadError } = await supabase.storage
                    .from('blog')
                    .upload(filePath, image);

                if (uploadError) throw uploadError;

                imagePath = filePath;
            }

            const { error } = await supabase
                .from('blogposts')
                .insert([{
                    title,
                    content: contentState,
                    thumbnail: imagePath
                }]);

            if (error) throw error;

            toast({
                title: "Blog post created.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            onClose();
            setTitle('');
            setImage(null);
        } catch (error: any) {
            toast({
                title: "An error occurred.",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const uploadImageCallBack = useCallback(async (file: File) => {
        try {
            const fileName = `${Date.now()}-${file.name}`;
            const { error } = await supabase.storage
                .from('blog')
                .upload(`editor/${fileName}`, file);

            if (error) throw error;

            const { data: urlData } = supabase.storage
                .from('blog')
                .getPublicUrl(`editor/${fileName}`);

            return { data: { link: urlData.publicUrl } };
        } catch (error) {
            console.error('Error uploading image:', error);
            return { data: { link: null } };
        }
    }, []);

    return (
        <Flex className="blog-wrapper" flexDir="column">
            <IconButton mb={"12px"} alignSelf={"flex-end"} onClick={onOpen} aria-label="Add post" icon={<NewMessage height={"18px"} />}>Add New Blog Post</IconButton>

            {/* {posts.map(post => {
                return <>
                    <div key={post.id}>
                        <Editor
                            editorState={EditorState.createWithContent(convertFromRaw(post.content))}
                            readOnly={true}
                            toolbarHidden={true}
                            wrapperClassName="blog-post-wrapper"
                            editorClassName="blog-post-editor"
                        />
                    </div>
                </>
            })} */}

            <Flex className="blog-posts">
            {!error && posts.length > 0 && posts.map(post => {
                return <BlogCard key={post.id} {...post}/>
             })}
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose} size="xxl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create a new blog post</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Title</FormLabel>
                                <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter title" />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Content</FormLabel>
                                {/* <LexicalEditor hasNav={true} /> */}
                                {/* <LexicalEditor hasNav={true} /> */}

                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    toolbar={{
                                        options: ['inline', 'fontSize', 'list', 'image'],
                                        image: {
                                            className: undefined,
                                            component: undefined,
                                            popupClassName: undefined,
                                            urlEnabled: true,
                                            uploadEnabled: true,
                                            alignmentEnabled: true,
                                            uploadCallback: uploadImageCallBack,
                                            previewImage: true,
                                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                            alt: { present: false, mandatory: false },
                                            defaultSize: {
                                                height: 'auto',
                                                width: 'auto',
                                            },
                                        },
                                    }}
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(newState) => setEditorState(newState)}
                                    onContentStateChange={(newState) => setContentState(newState)}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Image</FormLabel>
                                <Box
                                    {...getRootProps()}
                                    borderWidth={2}
                                    borderStyle="dashed"
                                    rounded="md"
                                    p={4}
                                    textAlign="center"
                                    cursor="pointer"
                                >
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <h3>Drop the image here ...</h3>
                                    ) : (
                                        <h6>Drag 'n' drop an image here, or click to select one</h6>
                                    )}
                                    {image && (
                                        <Image src={URL.createObjectURL(image)} alt="Preview" maxH="200px" mt={2} />
                                    )}
                                </Box>
                            </FormControl>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    )
}