import { Accordion, AccordionButton, AccordionButtonProps, AccordionIcon, AccordionItem, AccordionItemProps, AccordionPanel, Flex, Link, Progress, Text } from "@chakra-ui/react"
import CustomTable from "./CustomTable"
import { MouseEvent, MouseEventHandler, ReactNode } from "react"
import "../assets/styles/accordion.css"

interface CustomAccordionItemProps extends AccordionButtonProps {
    accordionTitle: string,
    accordionText: string,
    accordionTags?: string[] | undefined,
    children: ReactNode,
    loading?: boolean,
    onClick?: MouseEventHandler
}

const CustomAccordionItem = ({ accordionTitle, accordionTags, accordionText, children, onClick, loading = false }: CustomAccordionItemProps) => {

    const handleTitleClick: MouseEventHandler<Element> = (e) => {
        const classList: DOMTokenList = (e.target as any).classList
        const isTitle = classList.contains("custom-accordion-title")
        if (onClick && !isTitle) {
            onClick(e)
        }
    }

    return (
        <AccordionItem className="outerBgBox customAccordionItem">
            <h2>
                <AccordionButton columnGap={"6px"} onClick={handleTitleClick} _expanded={{ bg: "rgba(34, 34, 34, 1)" }}>
                    <AccordionIcon />
                    <Flex direction={"column"}>
                        <Flex flex='1' textAlign='left' whiteSpace={"pre"}>
                            <Text
                                onClick={(e) => window.innerWidth > 768 && e.preventDefault()}
                                fontSize="xl"
                                color={"#E41E20"}
                                className="custom-accordion-title"
                                style={{ userSelect: "text", cursor: "text" }}>{accordionTitle}</Text>
                            <Text> - </Text>
                            <Text fontSize="md">{accordionText}</Text>
                        </Flex>
                        <Flex>
                            {accordionTags && accordionTags.map(tag => <Link key={tag} href={`/tag/${tag}`} onClick={(e) => {e.preventDefault(); window.location.href = `/tag/${tag}`}} className="customAccordionTag">{tag}</Link>)}
                        </Flex>
                    </Flex>
                </AccordionButton>
            </h2>
            {!loading &&
                <AccordionPanel pb={4} className="" style={{ width: "auto", boxSizing: "border-box", overflowX: "auto" }}>
                    {children}
                </AccordionPanel>
            }
            {loading &&
                <AccordionPanel pb={4} className="" style={{ width: "auto", boxSizing: "border-box", overflowX: "auto", marginTop: "0", padding: "0" }}>
                    <Progress size='xs' isIndeterminate colorScheme={"red"} sx={{ "[role=progressbar]": { backgroundImage: "linear-gradient(to right, transparent 0%, rgba(228, 30, 32, 0.9) 50%, transparent 100%)" } }} />
                </AccordionPanel>
            }
        </AccordionItem >
    )
}

export default CustomAccordionItem