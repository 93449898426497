import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast } from "@chakra-ui/react";
import { useAuthStore } from "../store/useAuthStore"; // Adjust the import path to your Zustand store
import { AuthError } from "@supabase/gotrue-js";
import { supabase } from "../api/supabase";

interface LoginProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    aal: string | undefined;
}

export default function Login({ open, setOpen, aal }: LoginProps) {
    const toast = useToast();
    const initialRef = useRef<HTMLInputElement>(null);
    const mfaInputRef = useRef<HTMLInputElement>(null);
    const [email, setEmail] = useState(''); // Use email as the identifier for login
    const [password, setPassword] = useState('');
    const login = useAuthStore((state) => state.login); // Access the login function from the store
    const userData = useAuthStore((state) => state.user);
    const [error, setError] = useState('') // holds an error message
    const [verifyCode, setVerifyCode] = useState<string>("");

    const handleLogin = async (e: any, prevent = false) => {
        if (prevent) {
            e.preventDefault()
        }
        try {
            await login(email, password);
            setOpen(false); // Close the modal on successful login
        } catch (error: unknown) {
            if (error instanceof AuthError) {
                toast({
                    title: error.message,
                    status: "warning",
                    duration: 3000
                })
            }
            // console.log("Login error:", (error as AuthError).message);
        }
    };

    const handleMfa = async (e: any) => {
        e.preventDefault();
        if(!userData || !userData.factors || userData.factors.length === 0 || !userData.factors[0].id) {
            window.location.href = '/';
            return;
        }
        const factorId = userData?.factors[0].id;

        setError('');
        if (factorId.length < 6 || verifyCode.length < 6) {
            toast({
                title: "Please enter a valid number",
                status: "warning",
                duration: 3000
            })
            return;
        }

        const challenge = await supabase.auth.mfa.challenge({ factorId })
        if (challenge.error) {
            setError(challenge.error.message)
            // throw challenge.error
            return;
        }

        const challengeId = challenge.data.id

        const verify = await supabase.auth.mfa.verify({
            factorId,
            challengeId,
            code: verifyCode,
        })
        if (verify.error) {
            setError(verify.error.message)
            // throw verify.error
            return;
        } else {
            window.location.href = '/';
        }
    }

    useEffect(() => {
        if (!error) return;
        toast({
            title: error,
            status: "warning",
            duration: 3000
        })
    }, [error])

    useEffect(()=> {
        if(aal === "aal1") mfaInputRef.current?.focus()
    }, [aal])

    return (
        <Modal isOpen={open} initialFocusRef={aal === "aal1" ? mfaInputRef : initialRef} onClose={() => setOpen(false)} isCentered={true}>
            <ModalOverlay />
            <ModalContent className="modal-content">
                <ModalHeader>Log In</ModalHeader>
                <ModalCloseButton />
                {!aal &&
                    <FormControl>
                        <form onSubmit={(e) => handleLogin(e, true)}>
                            <ModalBody>
                                <Input
                                    ref={initialRef}
                                    placeholder='Email'
                                    size='lg'
                                    marginBottom="3"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Input
                                    placeholder='Password'
                                    type="password"
                                    size='lg'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </ModalBody>

                            <ModalFooter>
                                <Button mr={3} type="submit">
                                    Log In
                                </Button>
                                <Button mr={3} onClick={() => setOpen(false)}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </form>
                    </FormControl>
                }
                {aal === "aal1" &&
                    <FormControl>
                        <form onSubmit={(e) => handleMfa(e)}>
                            <ModalBody>
                                <Input
                                    ref={mfaInputRef}
                                    placeholder='Enter Authenticator Code'
                                    size='lg'
                                    marginBottom="3"
                                    value={verifyCode}
                                    onChange={(e) => setVerifyCode(e.target.value)}
                                />
                            </ModalBody>

                            <ModalFooter>
                                <Button mr={3} type="submit">
                                    Log In
                                </Button>
                            </ModalFooter>
                        </form>
                    </FormControl>
                }
            </ModalContent>
        </Modal >
    );
}
