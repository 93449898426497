import { useEffect, useState } from "react";
import { supabase } from "../api/supabase";
import { RawDraftContentState } from "draft-js";
import { PostgrestError } from "@supabase/supabase-js";
import { IBlogPost } from "../types/blog"

export function useBlogposts(postId?: number) {
    const [posts, setPosts] = useState<IBlogPost[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<PostgrestError | undefined>(undefined);
  
    useEffect(() => {
      fetchBlogposts();
    }, []);
  
    async function fetchBlogposts() {
      try {
        setLoading(true);
        let query = supabase.from('blogposts').select('*');
        
        if (postId) {
          query = query.eq('id', postId); // Fetch the specific post by ID
        }
        
        const { data, error } = await query;
        
        if (error) throw error;
  
        setPosts(data || []); // Ensure posts is set to an empty array if no data is returned
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  
    return { posts, loading, error, refetch: fetchBlogposts };
  }