import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { callApiProxy } from '../api/callApiProxy';
import ASNChart from '../components/ASNChart';
import "../assets/styles/dashboard.css";
import CountryChart from '../components/CountryChart';
import CustomPieChart from '../components/CustomPieChart';
import { dashboardRepo } from '../api/dashboardRepo';
import { AxiosResponse } from 'axios';

interface ASNRes {
  asn: number;
  country_code: string;
  total_requests: number;
}

interface CVERes {
  cve: string;
  cve_count: number;
}

interface TAGRes {
  tags: string;
  tag_count: number;
}

interface CountriesRes {
  country: string;
  count: number;
}

const Dashboard = () => {
  const [asnData, setAsnData] = useState<ASNRes[] | undefined>(undefined);
  const [tagData, setTagData] = useState<TAGRes[]>([]);
  const [cveData, setCveData] = useState<CVERes[]>([]);
  const [countriesData, setCountriesData] = useState<CountriesRes[] | undefined>(undefined);

  useEffect(() => {
    dashboardRepo.getTopAsn()
      .then((response: AxiosResponse<ASNRes[]>) => setAsnData(response.data));
      dashboardRepo.getTopCountries()
        .then((response: AxiosResponse<CountriesRes[]>) => setCountriesData(response.data));
    // callApiProxy('top-cve', "POST", { data: '' })
    //   .then((data: any) => setCveData(JSON.parse(data)));
  }, []);

  return (
    <Flex className="dashboardWrapper" style={{ width: "81%", margin: "0 auto", marginTop: "30px" }} justifyContent="space-between">
      {/* <div style={{ width: "48%" }}><ASNChart data={asnData} /></div>
      <div style={{ width: "48%" }}><CountryChart data={asnData} /></div> */}
      <div style={{ width: "48%" }}><CustomPieChart data={countriesData} heading={"Top 10 Countries"} /></div>
      <div style={{ width: "48%" }}><CustomPieChart data={asnData ? asnData.map(asnData => {return {...asnData, asn: "ASN" + asnData.asn}}) : undefined} heading={"Top 10 ASNs"} /></div>
    </Flex>
  );
};

export default Dashboard;
