import { Divider, Flex, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useBlogposts } from "../hooks/useBlogPosts";
import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { format } from "date-fns";

export default function Post() {
    const navParams = useParams();
    const { posts, error } = useBlogposts(parseInt(navParams.postId || "0"));
    const post = posts[0];

    if(posts.length == 0 || error) return <></>;

    return (
        <>
        <Divider mt={6} borderBottomWidth={2} />
        <Flex className="blog-wrapper" alignItems="flex-start" flexDir="column">
            <Text fontSize="60px" fontWeight="700">{post.title}</Text>
            <Text fontSize="21px" fontWeight="400">{format(post.created_at, "dd MMMM yyyy")}</Text>
            <Divider mt={6} borderBottomWidth={2} />
            <Editor
                editorState={EditorState.createWithContent(convertFromRaw(post.content))}
                readOnly={true}
                toolbarHidden={true}
                wrapperClassName="blog-post-wrapper"
                editorClassName="blog-post-editor"
            />
        </Flex>
        </>
    )
}