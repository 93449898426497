export default function matchStringType(str: string) {
    const patterns = {
        CVE: /^CVE-\d{4}-\d{4,}$/,
        IPv4: /^\b(?:\d{1,3}\.){3}\d{1,3}\b$/,
        UnixPath: /^\/[^\0]+?$/,
        WindowsPath: /^[a-zA-Z]:\\[^\\/:*?"<>|\r\n]+$/
    };

    for (const [type, regex] of Object.entries(patterns)) {
        if (regex.test(str)) {
            return type; // If a pattern matches, return the type immediately
        }
    }

    // After all patterns are tested, if no match is found and the string length is more than 2, return "Tag".
    // This logic is placed after the loop to ensure it only executes if no patterns match.
    if (str.length > 2) {
        return "Tag";
    }

    return undefined; // If no conditions are met, return undefined
}