import { cssVar, extendTheme, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from "@chakra-ui/theme-tools";

const $arrowBg = cssVar("popper-arrow-bg");

const config = {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    // Define custom color schemes
    highImpact: {
      100: "#E41E202E",
      200: '#E41E20'
    },
    moderateImpact: {
      100: '#e4811e2e',
      200: '#e4811e'
    },
    lowImpact: {
      100: '#22e41e2e',
      200: '#22e41e'
    },
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: mode('white', 'black')(props),
      }
    })
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '0', // This removes the border radius from all buttons
      },
    },
    Progress: {
      filledTrack: {
        bg: (props: any) => props.colorScheme,
      }
    },
    Heading: {
      baseStyle: {
        fontFamily: "'Satoshi', sans-serif"
      }
    },
    Tooltip: {
      baseStyle: {
        borderRadius: "9px",
        backgroundColor: 'rgba(0, 0, 0, 1)',
        border: '1px solid var(--chakra-colors-chakra-border-color)',
        color: "#fff",
        padding: "9px 12px",
        [$arrowBg.variable]: "rgba(0, 0, 0, 1)",
      }
    },
    Modal: {
      baseStyle: {
        dialog: {
          background: "rgba(34, 34, 34, 1)",
        }
      }
    }
  }
}

const theme = extendTheme(config)

export default theme
