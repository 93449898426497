import { useLocation, useParams } from "react-router-dom";
import processApiProxy from "../api/proxessApiProxy";
import { useEffect, useRef, useState } from "react";
import { Line, LineChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, AreaChart, Area } from "recharts";
import { Accordion, Box, Button, Container, Heading, Icon, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Th, Thead, Tr, Text, Flex, Collapse, useToast, Tooltip as ChakraTooltip } from "@chakra-ui/react";
import "../assets/styles/search.css";
import { useDraggable } from "react-use-draggable-scroll";
import { isTemplateExpression } from "typescript";
import IPCard from "../components/Search/IPCard";
import { ImNewTab } from "react-icons/im";
import { ChevronDownIcon } from '@chakra-ui/icons';
import { addDays, format } from "date-fns"
import matchStringType from "../utils/stringRegex";
import countByKey, { groupAndCountByDay } from "../utils/countByKey";
import CustomPieChart from "../components/CustomPieChart";
import CustomAccordionItem from "../components/CustomAccordionItem";
import CustomTable from "../components/CustomTable";
import { callApiProxy } from "../api/callApiProxy";
import { IpData, getIpLogs } from "./CVE";
import "../assets/styles/cve.css";
import CVEBg from "../assets/cve-bg.png";
import { IoFilterSharp } from "react-icons/io5";
import CustomLineGraph from "../components/CustomLineGraph";

export default function Search() {
    const navState = useLocation();
    const navParams = useParams();
    const [proxyData, setProxyData] = useState<any>(undefined);
    const [groupedLogs, setGroupedLogs] = useState<any>();
    const [activeIndex, setActiveIndex] = useState(-1); // Add state to track hover
    const [accordionOpenedLogs, setAccordionOpenedLogs] = useState<any>()
    const [ipCount, setIpCount] = useState<any>(null);
    const [pathCount, setPathCount] = useState<any>()
    const [ipData, setIpData] = useState<IpData[]>();
    const [loading, setLoading] = useState(false);
    const [tagCollapse, setTagCollapse] = useState(false);
    const toast = useToast()

    useEffect(() => {
        if (navParams.searchString) {
            processApiProxy(navParams.searchString).then(res => {
                setIpCount(countByKey(res.logs, "remote_ip"))
                setProxyData(res);
            });
        }
        // } else if (navState.state) {
        //     setIpCount(countByKey(navState.state.response.logs, "remote_ip"))
        //     setProxyData(navState.state.response);
        // }
    }, [navParams.searchString]);

    useEffect(() => {
        if (proxyData && proxyData.logs) {
            const tempGroupedLogs = groupAndCountByDay(proxyData.logs).sort((a, b) => +new Date(a.day) - +new Date(b.day))
            setGroupedLogs(tempGroupedLogs.map((log) => {
                const [year, month, day] = log.day.split('-');
                const date = new Date(Number(year), Number(month) - 1, Number(day));

                const formattedDate = `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })}`;

                return { ...log, day: formattedDate };
            }));
            setIpCount(countByKey(proxyData.logs, "remote_ip"))
            setPathCount(countByKey(proxyData.logs, "request_path").filter((e: any) => e.requestPath !== ''))
        }
    }, [proxyData]);

    useEffect(() => {
        if (ipCount) {
            callApiProxy("ip-data", "POST", { data: ipCount && ipCount.map((ipObj: any) => ipObj.remote_ip) }).then(res => {
                const response = JSON.parse(res) as IpData[];
                response.forEach((ipData, i) => {
                    const ipDataCount = ipCount.find((ip: any) => ip.remote_ip === ipData.client_ip).count
                    response[i]["count"] = ipDataCount
                    console.log(proxyData.logs)
                    response[i]["lastAttackDate"] = proxyData.logs.find((cve: any) => cve.remote_ip === ipData.client_ip).time
                })
                setIpData(response);
            })
        }
    }, [ipCount])

    // Function to handle mouse enter and leave on bars
    const handleMouseEnter = (data: any, index: number) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(-1);
    };

    const filterDateOptions = {
        all: { index: -1, string: "All" },
        day: { index: 1, string: "Today" },
        week: { index: 7, string: "This Week" },
        month: { index: 30, string: "This Month" },
    }
    const [filterDate, setFilterDate] = useState(filterDateOptions.month)
    // console.log(filterDate.string)

    let searchType: any = matchStringType(navParams.searchString ? navParams.searchString : "");
    const isFirstRender = useRef(true)
    useEffect(() => {
        if (!navParams.searchString) return;
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        let options = "";
        const dateNow = new Date();
        if (filterDate.index !== -1) options += "fromtime=" + (format(addDays(dateNow, -filterDate.index), 'yyyy-MM-dd HH:mm:ss'));

        processApiProxy(navParams.searchString, options).then(res => {

            if (res.logs.length > 0) {
                setProxyData(res)
            } else {
                toast({
                    title: "No results found for this time range",
                    status: "warning",
                    duration: 3000
                })
                setFilterDate(filterDateOptions.all)
            }
        });
    }, [filterDate.string])

    const cveCount = proxyData && countByKey(proxyData.logs, "cve")

    const tagCount = proxyData && (() => {
        const tagArr: { tags: string; count: number }[] = [];
        const tagCountMap: Record<string, number> = {};

        proxyData.logs.forEach((log: any) => {
            log.tags.forEach((tag: string) => {
                if (tagCountMap[tag]) {
                    tagCountMap[tag]++;
                } else {
                    tagCountMap[tag] = 1;
                }
            });
        });

        for (const tag in tagCountMap) {
            tagArr.push({ tags: tag, count: tagCountMap[tag] });
        }

        return tagArr;
    })()


    const sortedCveCount = cveCount && (cveCount.toSorted((a: any, b: any) => { return b.count - a.count }))

    const sortedTags = tagCount && (tagCount.toSorted((a: any, b: any) => { return b.count - a.count }))
    // console.log(groupedLogs)

    return (
        <Flex direction="column" className="cveMainDetails" backgroundImage={CVEBg}>
            <Heading as='h1' size='3xl' className={"searchQueryTitle"} textAlign={"center"}>{navParams.searchString}</Heading>
            {groupedLogs ?
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Menu>
                        <MenuButton as={Button} className="dateFilterChart" sx={{
                            margin: "6px 0px 9px 0px",
                            padding: "12px 30px",
                            alignSelf: "end",
                            background: "rgba(34, 34, 34, 1)",
                            zIndex: 1,
                            borderRadius: "9px"
                        }} leftIcon={<div style={{paddingRight: "15px"}}><IoFilterSharp /></div>}>
                            {filterDate.string}
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => setFilterDate(filterDateOptions.day)}>Today</MenuItem>
                            <MenuItem onClick={() => setFilterDate(filterDateOptions.week)}>Week</MenuItem>
                            <MenuItem onClick={() => setFilterDate(filterDateOptions.month)}>Month</MenuItem>
                            <MenuItem onClick={() => setFilterDate(filterDateOptions.all)}>All</MenuItem>
                        </MenuList>
                    </Menu>
                    {/* <CustomLineGraph groupedLogs={groupedLogs} handleMouseLeave={handleMouseLeave} aspect={3} /> */}
                </div>
                : ""}

            {cveCount && searchType == "IPv4" &&
                <div className="cvePage-pies-wrapper" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div className="cvePage-pies-wrapper" style={{ width: "45%" }}>
                        <CustomPieChart data={cveCount.filter((e: any) => e.cve !== "")} heading={"CVEs Related to this IP"} />
                    </div>
                    <div className="cvePage-pies-wrapper" style={{ width: "45%" }}>
                        <CustomPieChart data={tagCount.filter((e: any) => e.tags !== "")} heading={"Tags Related to this IP"} />
                    </div>
                </div>
            }

            {pathCount && (searchType == "UnixPath" || searchType == "WindowsPath") &&
                <>
                    <div className="cvePage-pies-wrapper" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div className="cvePage-pies-wrapper" style={{ width: "45%" }}>
                            <CustomPieChart data={ipCount} heading={"IPs Related to this Path"} />
                        </div>
                        <div className="cvePage-pies-wrapper" style={{ width: "45%" }}>
                            <CustomPieChart data={cveCount.filter((e: any) => e.cve !== "")} heading={"CVEs Related to this Path"} />
                        </div>
                    </div>

                    <Accordion allowToggle width={"100%"}>
                        {ipData && ipData.length > 0 && ipData.sort((a: IpData, b: IpData) => { return b.count - a.count }).map((ipObj: IpData, i: number) =>
                            <CustomAccordionItem key={i}
                                accordionTitle={ipObj.client_ip}
                                accordionText={`${ipObj.count} Attacks - ${ipObj.country_code} - ASN${ipObj.asn} - Latest Attack: ${ipObj.lastAttackDate}`}
                                onClick={() => { }}>
                                {proxyData && proxyData.logs.length > 0 &&
                                    <CustomTable
                                        headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                        rowsData={
                                            proxyData.logs.filter((log: any) => log.request_path.includes(navParams.searchString) && log.remote_ip === ipObj.client_ip).map((log: any) =>
                                                [log.time, log.request_path, log.user_agent, log.request_querystring]
                                            )
                                        } />
                                }
                            </CustomAccordionItem>
                        )}
                    </Accordion>
                </>
            }

            {tagCount && ipCount && searchType == "Tag" &&
                <>
                    <div className="cvePage-pies-wrapper" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div className="cvePage-pies-wrapper" style={{ width: "45%" }}>
                            <CustomPieChart data={ipCount} heading={"IPs Related to this tag"} />
                        </div>
                        <div className="cvePage-pies-wrapper" style={{ width: "45%" }}>
                            <CustomPieChart data={cveCount.filter((e: any) => e.cve !== "")} heading={"CVEs Related to this tag"} />
                        </div>
                    </div>
                    {/* START INFO COMPONENT TYPE2 */}
                    <div className="cvePage-pies-wrapper outerBgBox" style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "space-around" }}>
                        <div className="innerBgBox" style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-around", height: "100%", padding: "33px" }}>
                            <Collapse startingHeight={63} style={{ width: "54%" }} in={tagCollapse}>
                                <Flex flexDirection="column">
                                    <Text fontSize="xl" color={"#E41E20"}>Other Related Tags</Text>
                                    <Text fontSize="xl" color={""}>{sortedTags.map((tag: any) => tag.tags).join(", ")}</Text>
                                </Flex>
                            </Collapse>
                            <Flex width="42%" alignItems={"flex-start"}>
                                <Text fontSize="xl" color={"#E41E20"} margin={"0 auto"}>TOTAL ATTEMPTS</Text>
                                <div className="outerBgBox" style={{ padding: "6px 15px", margin: "0 auto", width: "fit-content" }}>
                                    <Text fontSize="xl" fontWeight={"600"} letterSpacing={"9px"} color={"#E41E20"}>{proxyData.logs.length}</Text>
                                </div>
                            </Flex>
                            <Flex marginTop="33px" width="100%" justifyContent={"center"}><Button onClick={() => setTagCollapse(!tagCollapse)}><ChevronDownIcon color="#E41E20" style={{ transition: ".3s", transform: 'rotate(' + (tagCollapse ? "180deg" : "0deg") + ')' }} /></Button></Flex>
                        </div>
                    </div>
                    {/* END INFO COMPONENT TYPE2 */}
                    <Accordion allowToggle width={"100%"}>
                        {ipData && ipData && ipData.sort((a: IpData, b: IpData) => { return b.count - a.count }).map((ipObj, i) =>
                            <CustomAccordionItem key={i}
                                accordionTitle={ipObj.client_ip}
                                accordionText={`${ipObj.count} Attacks - ${ipObj.country_code} - ASN${ipObj.asn} - Latest Attack: ${ipObj.lastAttackDate}`}
                                loading={false}
                                onClick={() => {
                                    // setLoading(true)
                                    // getIpLogs(ipObj.client_ip).then((res: any) => {
                                    //     setAccordionOpenedLogs(res.logs);
                                    //     setLoading(false)
                                    // })
                                    proxyData.logs.filter((log: any) => log.tags.includes(navParams.searchString))
                                }}>
                                {proxyData && proxyData.logs.length > 0 &&
                                    // <CustomTable
                                    //     headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                    //     rowsData={[["a1", "a2", "a3"], ["a1", "a2", "a3"], ["a1", "a2", "a3"]]} />
                                    <CustomTable
                                        headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                        rowsData={
                                            proxyData.logs
                                                .filter((log: any) => log.remote_ip === ipObj.client_ip)
                                                .slice(0, 90)
                                                .map((log: any) => [log.time, log.request_path, log.user_agent, log.request_querystring])
                                        } />
                                }
                            </CustomAccordionItem>
                        )}
                    </Accordion>
                </>
            }

            {proxyData && searchType === "IPv4" &&
                <Accordion allowToggle width={"100%"}>
                    {proxyData && cveCount.map((cveObj: any, i: number) =>
                        <CustomAccordionItem key={i}
                            accordionTitle={cveObj.cve === "" ? "Other Attacks" : cveObj.cve}
                            accordionText={`${cveObj.count} Attacks - Endpoint: ${endpointTrimmer((proxyData.logs.find((ipLog: any) => ipLog.cve === cveObj.cve)).request_path)} - Latest attack: ${((proxyData.logs.filter((log: any) => log.cve === cveObj.cve))[0].time)}`}
                            onClick={() => { }}>
                            {proxyData && proxyData.logs.length > 0 &&
                                // <CustomTable
                                //     headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                //     rowsData={[["a1", "a2", "a3"], ["a1", "a2", "a3"], ["a1", "a2", "a3"]]} />
                                <CustomTable
                                    headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                    rowsData={
                                        proxyData.logs.filter((log: any) => log.cve === cveObj.cve).map((log: any) =>
                                            [log.time, log.request_path, log.user_agent, log.request_querystring]
                                        )
                                    } />
                            }
                        </CustomAccordionItem>
                    )}
                </Accordion>
            }
        </Flex>
    );
}

function CustomBarShape(props: any) {
    const { x, y, width, height, hovered } = props;

    // Adjust fill color based on hover state
    const fillColor = hovered ? "rgba(228, 30, 32, 0.39)" : "rgba(228, 30, 32, 0.18)";

    return (
        <g>
            {/* Main bar shape */}
            <rect x={x} y={y} width={width} height={height} fill={fillColor} />
            {/* Top border */}
            <path d={`M${x} ${y} h${width}`} stroke="red" strokeWidth={1} />
            {/* Right border */}
            <path d={`M${x + width} ${y} v${height}`} stroke="red" strokeWidth={1} />
            {/* Left border */}
            <path d={`M${x} ${y} v${height}`} stroke="red" strokeWidth={1} />
        </g>
    );
}

function endpointTrimmer(endpoint: string) {
    if (endpoint.length < 30) {
        return endpoint;
    } else {
        return endpoint.substring(0, 30) + "..."
    }
}

// Function to process the logs and count occurrences
const processLogs = (logs: any, key: any) => {
    const countMap = logs.reduce((acc: any, log: any) => {
        const value = log[key];
        if (!value) return acc; // Skip empty values
        acc[value] = acc[value] ? acc[value] + 1 : 1;
        return acc;
    }, {});

    return Object.entries(countMap).map(([value, count]) => ({ value, count })).sort((a: any, b: any) => b.count - a.count);
};

const DataTables = ({ logs }: any) => {
    const cves = processLogs(logs, 'cve');
    // const responseCodes = processLogs(logs, 'response_code');
    const requestPaths = processLogs(logs, 'request_path');
    const requestQueryStrings = processLogs(logs, 'request_querystring');
    const remoteIp = processLogs(logs, 'remote_ip');
    const tags = processLogs(logs, 'tags');

    const table1 = useRef<any>();
    // const table2 = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const table3 = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const table4 = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const table5 = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const table6 = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const { events: events1 } = useDraggable(table1);
    // const { events: events2 } = useDraggable(table2);
    const { events: events3 } = useDraggable(table3);
    const { events: events4 } = useDraggable(table4);
    const { events: events5 } = useDraggable(table5);
    const { events: events6 } = useDraggable(table6);


    // Table component for displaying data
    const TableComponent = ({ title, data }: any) => {
        let hrefPath: string = "";
        switch (title) {
            case "Tags":
                hrefPath = "/search/";
                break;
            case "IPs":
                hrefPath = "/search/";
                break;
            case "CVEs":
                hrefPath = "/cve/";
                break;
            case "Request Paths":
                hrefPath = "/search/";
                break;
        }

        return (
            <Box>
                <Heading size="md" mb={2}>{title}</Heading>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Count</Th>
                            <Th>{title}</Th>
                            {title === "CVEs" ? <Th>Reference</Th> : ""}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((item: any, index: number) => {
                            let link = title === "CVEs" ? logs.find((log: any) => log.cve === item.value).reference : "";
                            return <Tr key={index}>
                                <Td style={{ width: "90px" }}>{item.count}</Td>
                                <Td><a href={hrefPath !== "" ? hrefPath + encodeURIComponent(item.value) : "#"}>{item.value}</a></Td>
                                {title === "CVEs" ?
                                    <Td style={{ display: "flex", alignItems: "center", columnGap: "9px" }}>
                                        <a href={link} target="_blank">Open Link</a><Icon as={ImNewTab} />
                                    </Td>
                                    : ""}
                            </Tr>
                        })}
                    </Tbody>
                </Table>
            </Box>
        )
    };

    return (
        <div className="searchWrapper">
            <Container className={"tableWrapper"} style={{ minWidth: "100%", display: "grid" }}>
                <div ref={table1} {...events1} ><TableComponent title="CVEs" data={cves} /></div>
                {/* <div ref={table2} {...events2} ><TableComponent title="Response Codes" data={responseCodes} /></div> */}
                <div ref={table3} {...events3} style={{ display: requestPaths.length > 1 ? "block" : "none" }}><TableComponent title="Request Paths" data={requestPaths} /></div>
                <div ref={table4} {...events4} ><TableComponent title="Request Query Strings" data={requestQueryStrings} /></div>
                <div ref={table5} {...events5} style={{ display: remoteIp.length > 1 ? "block" : "none" }}><TableComponent title="IPs" data={remoteIp} /></div>
                <div ref={table6} {...events6} style={{ display: tags.length > 1 ? "block" : "none" }}><TableComponent title="Tags" data={tags} /></div>
            </Container>
            {/* <IPCard logs={logs}/> */}
        </div>
    );
};

